import {extractModuleIdentifiers, ModuleNode} from '@backstage-components/base';
import {FC} from 'react';
import type {IAccessCodeProps, SchemaType} from './AccessCodeDefinition';
import {VerifyAccessCode} from './AccessCodeComponent';

export type AccessCodeProps = SchemaType & IAccessCodeProps;

export const AccessCodePreviewComponent: FC<
  ModuleNode<'AccessCode', AccessCodeProps>
> = (definition) => {
  const {component, props, config} = definition.module;
  return (
    <VerifyAccessCode
      component={component}
      config={config}
      {...extractModuleIdentifiers(definition.module)}
      props={props}
      broadcast={() => void 0}
      showId="00000000-2fbe-0000-0000-000000000000"
      form={{
        formState: {
          dirtyFields: {},
          errors: {},
          isDirty: false,
          isSubmitSuccessful: false,
          isSubmitted: false,
          isSubmitting: false,
          isValid: true,
          isValidating: false,
          submitCount: 0,
          touchedFields: {},
        },
        handleSubmit: () => () => Promise.resolve(),
        // @ts-expect-error this is a mock and we don't need it to have the
        // appropriate return value
        register: () => ({}),
      }}
    />
  );
};
